.hamburger:before,
.hamburger:after {
	transition: margin 150ms ease-in-out 150ms, transform 150ms ease-in-out;
}

.hamburgerActive:before,
.hamburgerActive:after {
	transition: margin 150ms ease-in-out, transform 150ms ease-in-out 150ms;
}

.hamburger span {
	transition: opacity 0ms ease-in-out 150ms;
}
